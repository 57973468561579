export const predefineds = {
  blacklistedId: 115,
  whitlistedId: 116,
}

export const memberStatus = {
  canecelled: { id: 713, slug: "memberStatus-cancelled" },
  expired: { id: 705, slug: "member-expired" },
  inActive: { id: 704, slug: "member-inactive" },
  terminated: { id: 702, slug: "member-terminated" },
  terminated: { id: 701, slug: "member-warned" },
  delayed: { id: 700, slug: "member-delayed" },
}

export const actionsIds = {
  endorsementTaskActionId: 701,
  changeProductPremiumActionId: "",
}

export const stakeholderStatus = {
  blacklisted: { id: 115, slug: "blacklisted" },
  whitelisted: { id: 116, slug: "whitelisted" },
}

export const commissionPaymentTypes = {
  agentType: {
    id: 717,
    name: "Agent Payment",
    slug: "commissionPayment-agent",
  },
  manageType: {
    id: 718,
    name: "Unit Manager Payment",
    slug: "commissionPayment-manager",
  },
  agencyType: {
    id: 719,
    name: "Agency Payment",
    slug: "commissionPayment-agency",
  },
}

export const endorsementTypes = {
  name: { id: 277, slug: "endorsement-beneficiary-name" },
  dateOfBirth: { id: 278, slug: "endorsement-beneficiary-dob" },
  policyCancel: { id: 284, slug: "endorsement-policy-cancellation" },
  addPlan: { id: 285, slug: "endorsement-add-co-participation" },
  removePlan: { id: 286, slug: "endorsement-stop-co-participation" },
  memberCancel: { id: 720, slug: "endorsement-holder-cancellation" },
  additional: { id: 753, slug: "endorsement-beneficiary-addition" },
  guaranteed: { id: 743, slug: "endorsement-beneficiary-details" },
  deletion: { id: 720, slug: "endorsement-holder-cancellation" },
  continuity: { id: 778, slug: "endorsement-beneficiary-details" },
  cobaby: { id: 741, slug: "endorsement-beneficiary-addition" },
  falseDeclaration: { id: 788, slug: "endorsement-beneficiary-details" },
  specialNotes: { id: 789, slug: "endorsement-beneficiary-details" },
}

export const memberDependencies = {
  principle: { id: 177, name: "Principal", slug: "beneficiary-principal" },
}
export const policyTypes = {
  individual: { id: 564, name: "Individual", slug: null },
}
