import React, { Fragment, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect,
} from "react-table"
import { Table, Row, Col } from "reactstrap"

import { DefaultColumnFilter } from "./filters"

//i18n
import { withTranslation } from "react-i18next"

import { TablePagination, TableActionsButtons, TableSkeleton } from "./index"
import { NoRecords, RowActions } from "components/TP/Common/Tables"
import {
  apiErrorrHandler,
  NotificationMessage,
  resetSearchColumnInput,
} from "utils"
import { Link } from "react-router-dom"
import { useModuleActions, usePageType } from "hooks"
import { eventManagerModuleID, productionEventsId } from "utils/modulesIds"
import { useSelector } from "react-redux"

// const TableContainer = React.forwardRef(
const TableContainer = ({
  columns,
  data,
  resetInput,
  basicTable,
  subModuleId,
  setResetInput,
  // isGlobalFilter,
  isJobListGlobalFilter,
  className,
  customPageSizeOptions = 10,
  addPageLink,
  handleAdd,
  inCludeExportButton,
  addDropDown,
  batchPageLink,
  showOptions,
  setShowOptions,
  fullColumns,
  pagination,
  hidePagination,
  getData,
  loading,
  sheetName,
  customCSVHeader,
  customCSVData,
  activateFunc,
  deactivateFunc,
  deleteFunc,
  handleClickUpdate,
  handleViewProcessing,
  handleClickStatus,
  handleClickClone,
  handleClickView,
  handleClickStandardCode,
  handleClickDependency,
  handleClickPrint,
  handleClickPolicyIssuing,
  handleChangePremiumClick,
  queryAddOn,
  moduleId,
  removeActionsColumns,
  hideRecordsDropdown,
  isBackPermission,
  backPageLink,
  isUnusedMarkPermission,
  handleAddMarkUnused,
  addOnButtons,
  tableActionsComponents,
  removeActionsButtons,
  includeCheckBoxes,
  idsArray,
  setIdsArray,
  updateMyData,
  handleClickAssignToMe,
  isActiveColumn,
  includeReloadButton = false,
  onReloadClick,
  t,
  y,
}) => {
  // number of rows per page
  const [perPage, setPerPage] = useState(customPageSizeOptions)
  const [allColumns, setAllColumns] = useState([])
  const { isUpdatePermission } = useModuleActions()

  const { loading: moduleLoading } = useSelector((state) => ({
    loading: state?.Module?.loading,
  }))

  const { isViewPage } = usePageType()

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    // rows,
    selectedFlatRows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    setAllFilters,
    state: { pageIndex, pageSize, selectedRowIds, sortBy },
  } = useTable(
    {
      columns: allColumns,
      data,
      manualPagination: true,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 1,
        pageSize: perPage,
      },
      manualFilters: true,
      manualSortBy: true,
      updateMyData,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  )

  const { defaultSearchData } = useMemo(() => {
    if (isActiveColumn) {
      return {
        defaultSearchData: {
          search_keys: "is_active",
          search_values: "1",
          sort_by: "",
          sort_type: "",
          search_value: "",
        },
      }
    } else {
      return {
        defaultSearchData: {
          search_keys: "",
          search_values: "",
          sort_by: "",
          sort_type: "",
          search_value: "",
        },
      }
    }
  }, [isActiveColumn])

  const [searchData, setSearchData] = useState({
    search_keys: isActiveColumn ? "is_active" : "",
    search_values: isActiveColumn ? "1" : "",
    sort_by: "",
    sort_type: "",
    search_value: "",
  })

  useEffect(() => {
    setSearchData(defaultSearchData)
  }, [JSON.stringify(defaultSearchData)])

  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    if (!resetInput) return
    if (resetInput) {
      setAllFilters([])
      setGlobalFilter([])
      setSearchData(defaultSearchData)
      resetSearchColumnInput()
      setResetInput(false)
    }
  }, [resetInput])

  const componentRef = React.createRef()

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  // handle CheckBoxes change
  const handleCheckBoxChange = (rowId) => {
    const isFound = idsArray.find((item) => item === rowId)
    if (isFound) {
      setIdsArray(idsArray.filter((item) => item !== rowId))
    } else {
      const newIds = [...idsArray, rowId]
      setIdsArray(newIds)
    }
  }

  useEffect(() => {
    if (!columns.length) {
      return
    }
    let removeUpdatePermission = false

    const modifiedColumns = columns.map((item) => {
      return item.accessor === "id"
        ? {
            ...item,
            Cell: (cellProps) => {
              const row = cellProps.row.original
              // remove update icon when module is event manager or production events module and
              // is_excuted property is true or 1
              removeUpdatePermission =
                (moduleId === eventManagerModuleID ||
                  moduleId === productionEventsId) &&
                cellProps.row.original.is_executed
                  ? true
                  : false
              return isUpdatePermission && !removeUpdatePermission ? (
                <Link
                  to="#"
                  className="text-success"
                  onClick={() => {
                    handleClickUpdate(row)
                  }}
                >
                  {row[item.accessor]}
                </Link>
              ) : (
                <Fragment>{row[item.accessor]}</Fragment>
              )
            },
          }
        : item
    })

    const columnsWithCheckBoxes = includeCheckBoxes
      ? [
          {
            Header: "#",
            id: "selectedIds",
            Cell: (cellprops) => {
              const rowId = cellprops.row.original.id
              return (
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={idsArray.find((item) => item === rowId)}
                  onChange={(e) => handleCheckBoxChange(rowId)}
                />
              )
            },
          },
          ...modifiedColumns,
        ]
      : modifiedColumns

    setAllColumns(
      // hide the actions in view pages
      isViewPage || removeActionsColumns
        ? [...columnsWithCheckBoxes]
        : [
            ...columnsWithCheckBoxes,
            {
              Header: "Actions",
              accessor: "actions",
              disableFilters: true,
              disableSortBy: true,
              show: 1,
              Cell: (cellProps) => {
                // Actions Icons in each row with its props
                const row = cellProps.row.original
                return (
                  <RowActions
                    cellProps={cellProps}
                    onDelete={() => handleDelete(row.id)}
                    onActivate={() => handleActivateDeactivate(row)}
                    removeUpdatePermission={removeUpdatePermission}
                    onClickUpdate={
                      handleClickUpdate
                        ? () => {
                            handleClickUpdate(row)
                          }
                        : undefined
                    }
                    onClickProcessing={() => {
                      handleViewProcessing(row)
                    }}
                    onClickView={() => {
                      handleClickView(row)
                    }}
                    onClickStatus={() => {
                      handleClickStatus(row)
                    }}
                    onClickCopy={() => {
                      handleClickClone(row)
                    }}
                    onClickStandardCode={() => {
                      handleClickStandardCode(row)
                    }}
                    onClickDependency={() => {
                      handleClickDependency(row)
                    }}
                    onPolicyIssueingClick={() => {
                      handleClickPolicyIssuing(row)
                    }}
                    onChangePremiumClick={() => {
                      handleChangePremiumClick(row)
                    }}
                    onTaskAssignToMeClick={
                      handleClickAssignToMe
                        ? () => {
                            handleClickAssignToMe(row)
                          }
                        : undefined
                    }
                    subModuleId={subModuleId}
                    onClickLog={() => handleClickLog(row)}
                    onClickPrint={() => handleClickPrint(row)}
                  />
                )
              },
            },
          ]
    )
  }, [columns, includeCheckBoxes, idsArray])

  // Number of records per page
  const onChangeInSelect = (event) => {
    getData({
      page: currentPage,
      per_page: event.target.value,
      ...searchData,
      ...queryAddOn,
    })
    setPerPage(Number(event.target.value))
  }

  useEffect(() => {
    if (sortBy.length > 0) {
      sort()
    }
  }, [sortBy])

  const sort = () => {
    const sort_by = headerGroups[0].headers.filter(
      (header) => sortBy[0]?.id === header.id
    )[0]?.name
    const sort_type = sortBy.length ? (sortBy[0].desc ? "desc" : "Asc") : ""

    getData({
      page: currentPage,
      per_page: perPage,
      ...searchData,
      ...queryAddOn,
      sort_by,
      sort_type,
    })
    setSearchData({
      ...searchData,
      sort_by,
      sort_type,
    })
  }

  const search = () => {
    let searchObj = {}
    let searchParameters = {}
    const headers = headerGroups[0].headers

    for (let index = 0; index < headers.length; index++) {
      if (headers[index].filterValue && headers[index].canFilter) {
        if (headers[index].customSearchParameter) {
          searchParameters[headers[index].customSearchParameter] =
            headers[index].filterValue
        } else {
          searchObj[headers[index].name] = headers[index].filterValue
        }
      }
    }

    if (isActiveColumn && !searchObj?.is_active) {
      searchObj = { ...searchObj, is_active: 1 }
    }

    if (
      isActiveColumn &&
      searchObj?.is_active &&
      searchObj?.is_active === "undefined"
    ) {
      delete searchObj.is_active
    }

    let search_keys = Object.keys(searchObj).join(",")
    let search_values = Object.values(searchObj).join(",")

    setSearchData({ ...searchData, search_keys, search_values })
    getData({
      page: 1,
      per_page: perPage,
      ...searchData,
      ...queryAddOn,
      search_keys,
      search_values,
      ...searchParameters,
    })
    setCurrentPage(1)
  }

  const handleClickLog = (row) => {
    const { id } = row
    window.open(`/logs/${moduleId}/${id}`)
  }

  const handleActivateDeactivate = async (row) => {
    const { id, is_active } = row
    try {
      if (is_active === 0) {
        await activateFunc(id)
        NotificationMessage("Success", "activate")
      } else {
        await deactivateFunc(id)
        NotificationMessage("Success", "deactivate")
      }

      setSearchData(defaultSearchData)

      getData({
        page: currentPage,
        per_page: perPage,
        ...searchData,
        ...queryAddOn,
      })
      setAllFilters([])
      setGlobalFilter([])
      resetSearchColumnInput()
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    }
  }

  const handleDelete = async (id) => {
    try {
      await deleteFunc(id)
      NotificationMessage("Success", "delete")
      setSearchData(defaultSearchData)
      getData({
        page: currentPage,
        per_page: perPage,
        ...searchData,
        ...queryAddOn,
      })
      setAllFilters([])
      setGlobalFilter([])
      resetSearchColumnInput()
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    }
  }

  return (
    <Fragment>
      {!removeActionsButtons && (
        <Row className="mb-4 mt-3 justify-content-md-between justify-content-center">
          <Col
            md={1}
            className={`${
              hideRecordsDropdown ? "opacity-0 mb-1" : "opacity-1 mb-4 md-mb-0"
            }`}
          >
            <select
              className="form-select"
              style={{ width: "fit-content" }}
              value={perPage}
              onChange={onChangeInSelect}
            >
              {[10, 25, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </Col>

          {/* Hide the action buttons in view pages */}
          {!isViewPage && (
            <Col xs="12" md="11">
              {/* action buttons at the top of the table (Add new , Export ,...etc) */}
              <TableActionsButtons
                handleAdd={handleAdd}
                addPageLink={addPageLink}
                addDropDown={addDropDown}
                batchPageLink={batchPageLink}
                isBackPermission={isBackPermission}
                backPageLink={backPageLink}
                addOnButtons={addOnButtons}
                tableActionsComponents={tableActionsComponents}
                // for show / hide columns
                showOptions={showOptions}
                setShowOptions={setShowOptions}
                fullColumns={fullColumns}
                isUnusedMarkPermission={isUnusedMarkPermission}
                handleAddMarkUnused={handleAddMarkUnused}
                data={data}
                columns={columns}
                sheetName={sheetName}
                customCSVHeader={customCSVHeader}
                customCSVData={customCSVData}
                inCludeExportButton={inCludeExportButton}
                includeReloadButton={includeReloadButton}
                onReloadClick={onReloadClick}
                // ref={componentRef}
                subModuleId={subModuleId}
              />
            </Col>
          )}
        </Row>
      )}

      {/* the table  */}
      <div
        className="table-responsive react-table"
        // ref={componentRef}
      >
        <Table
          hover={!loading}
          {...getTableProps()}
          className={className}
          style={{
            position: "relative",
            height: loading && "700px",
          }}
        >
          {/* {loading ? <TableSkeleton /> : <> */}
          <thead className="table-light table-nowrap">
            {headerGroups.map((headerGroup) => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    key={column.id}
                    {...column.getHeaderProps()}
                    className="text-capitalize"
                  >
                    <div className="mb-2" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>

                    {/* search bar in each column header */}
                    {!basicTable && (
                      <DefaultColumnFilter
                        column={column}
                        search={search}
                        isActiveColumn={isActiveColumn}
                        loading={moduleLoading}
                      />
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {loading ? (
            <tbody>
              <tr>
                <td colSpan={allColumns?.length}>
                  <div
                    style={{
                      position: "absolute",
                      left: "0",
                      right: "0",
                    }}
                  >
                    <TableSkeleton />
                  </div>
                </td>
              </tr>
            </tbody>
          ) : (
            <>
              <tbody {...getTableBodyProps()}>
                {data.length ? (
                  page.map((row) => {
                    prepareRow(row)
                    return (
                      <Fragment key={row.getRowProps().key}>
                        <tr
                          {...row.getRowProps()}
                          className={
                            row.original.rowClassNames
                              ? row.original.rowClassNames
                              : ""
                          }
                        >
                          {row.cells.map((cell) => {
                            return (
                              <td
                                key={cell.id}
                                {...cell.getCellProps()}
                                className="text-capitalize"
                              >
                                {cell.render("Cell")}
                              </td>
                            )
                          })}
                        </tr>
                      </Fragment>
                    )
                  })
                ) : (
                  <tr />
                )}
              </tbody>
            </>
          )}
        </Table>
        {!data.length && !loading ? <NoRecords /> : ""}
      </div>
      {/* Table Pagination Component */}
      {(pagination || !hidePagination) && (
        <Fragment>
          <TablePagination
            pagination={pagination}
            getData={(currentPage) => {
              getData({
                ...searchData,
                ...queryAddOn,
                page: currentPage,
                per_page: perPage,
              })
            }}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            resetInput={resetInput}
          />
        </Fragment>
      )}
    </Fragment>
  )
}
// )

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  t: PropTypes.any,
}
TableContainer.displayName = "TableContainer"

export default withTranslation()(TableContainer)
