import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer"
import React from "react"
import arabicFont from "../../../../Fonts/NotoSansArabic-VariableFont_wdth,wght.ttf"
import moment from "moment"
import { previewDateFormat } from "constants/TPA"

Font.register({ family: "arabicFont", src: arabicFont })
// Font.register({ family: 'Roboto', src: source });

const { ToWords } = require("to-words")

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "col",
    backgroundColor: "#fff",
    width: "100%",
    height: "100%",
    paddingVertical: 10,
    fontSize: "12px",
  },
  topSection: {
    marginTop: "20px",
    marginBottom: "15px",
    paddingHorizontal: 30,
  },
  image: {
    width: 120,
    height: 40,
    marginRight: "20px",
  },
  boldText: {
    fontSize: "17px",
    marginLeft: "35px",
    marginTop: "40px",
    fontWeight: "900",
    fontFamily: "Helvetica-Bold",
  },

  tableData: {
    marginTop: "12px",
    flexDirection: "row",
    paddingHorizontal: 5,
  },
  ArabicData: {
    marginTop: "12px",
    paddingRight: 30,
    textAlign: "right",
  },
  tableSide: {
    flex: "1",
  },
  tableSideData: {
    fontSize: "12px",
    fontWeight: "600",
    paddingHorizontal: 5,
    marginLeft: "20px",
    justifyContent: "space-between",
  },
  leftSide: {
    fontSize: "12px",
    fontWeight: "900",
  },
  rightSide: {
    fontSize: "12px",
    fontWeight: "900",
  },
  inside: {
    borderBottom: "1px solid black",
    color: "black",
    width: "900px",
    marginBottom: "8px",
    marginLeft: "55px",
  },
  bottomSection: {
    alignItems: "center",
    marginTop: "17px",
  },
  amountData: {
    borderRight: "1px solid black",
    padding: "3px",
  },

  date: {
    flexDirection: "row",
    marginLeft: "66%",
  },
  arabic: {
    fontFamily: "arabicFont",
  },
})

const HelperComponent = ({ Name, Value, words }) => {
  return (
    <View style={styles.tableData}>
      <Text style={styles.tableSideData}>{Name}</Text>
      <Text style={styles.inside}>
        <Text style={{ textTransform: "capitalize" }}>
          {Value}
          {"                "}
        </Text>
      </Text>
    </View>
  )
}

const PaymentReceiptPdf = ({ paymentReceipts }) => {
  const toWords = new ToWords()

  return (
    <Document title="ACES | Payment Receipts">
      {paymentReceipts?.map((payment) => (
        <Page
          size="A5"
          orientation="landscape"
          style={styles.page}
          key={payment.id}
        >
          <View style={styles.topSection}>
            <View style={styles.image}></View>
          </View>
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <Text style={{ ...styles.boldText, fontSize: "15px" }}>
              Receipt
            </Text>{" "}
            <Text
              style={{
                ...styles.ArabicData,
                ...styles.arabic,
                fontWeight: "bold",
                marginTop: "35px",
                paddingHorizontal: 40,
              }}
            >
              ايصال
            </Text>
          </View>
          <View style={{ ...styles.tableData, marginTop: "3px" }}>
            <View
              style={{
                ...styles.tableSide,
                marginTop: 0,
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <View style={styles.tableData}>
                <Text style={styles.tableSideData}>no:</Text>
                <Text
                  style={{
                    ...styles.inside,
                    border: "none",
                    width: 0,
                    marginLeft: "3px",
                  }}
                >
                  <Text>{payment?.reference}</Text>
                </Text>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  border: "1px solid black",
                  width: "50%",
                  height: "70%",
                  marginRight: "35px",
                  ...styles.arabic,
                }}
              >
                <Text
                  style={{
                    ...styles.amountData,
                    width: "90px",
                    paddingTop: "5px",
                    fontFamily: "Helvetica-Bold",
                  }}
                >
                  AMOUNT
                </Text>
                <View
                  style={{
                    ...styles.amountData,
                    width: "170px",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Text style={{}}>{payment?.currency?.symbol}</Text>
                  <Text>{payment?.amount}</Text>
                </View>
                <Text
                  style={{
                    ...styles.ArabicData,
                    marginTop: "0px",
                    paddingHorizontal: 30,
                    width: "60px",
                    textAlign: "right",
                    paddingBottom: "8px",
                  }}
                >
                  المبلغ
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.tableData}>
            <View style={styles.tableSide}>
              <HelperComponent
                Name="Received From:"
                Value={payment?.beneficiary?.name?.toUpperCase()}
              />
              <HelperComponent
                Name="The sum of:"
                Value={
                  payment.amount
                    ? toWords.convert(payment?.amount) +
                      " " +
                      payment?.currency?.name
                    : 0
                }
              />
              <HelperComponent
                Name="For:"
                Value={
                  payment?.category?.id === 817
                    ? `Settlement On   ${
                        payment?.beneficiary?.reference.split("-")[0]
                      }`
                    : `Payment on acc   ${
                        payment?.beneficiary?.reference.split("-")[0]
                      }`
                }
              />
            </View>

            <View
              style={{
                ...styles.tableArabicSide,
                ...styles.arabic,
                // paddingLeft: 50,
              }}
            >
              <Text style={{ marginTop: "9px", marginRight: "25px" }}>
                :وصلنا من{" "}
              </Text>
              <Text style={{ marginTop: "9px", marginRight: "25px" }}>
                :مبلغ وقدره
              </Text>
              <Text style={{ marginTop: "9px", marginLeft: "20px" }}>
                :وذلك
              </Text>
              <Text> </Text>
            </View>
          </View>
          <View style={styles.date}>
            <View
              style={{
                ...styles.tableData,
                marginTop: 0,
                paddingHorizontal: 0,
              }}
            >
              <Text
                style={{
                  ...styles.tableSideData,
                  marginTop: 7,
                  fontFamily: "Helvetica-Bold",
                }}
              >
                Date
              </Text>
              <Text
                style={{
                  ...styles.inside,
                  width: "37%",
                  marginLeft: 0,
                  marginRight: "2px",
                  marginBottom: 20,
                  marginTop: 5,
                }}
              >
                {payment?.date
                  ? moment(payment?.date).format(previewDateFormat)
                  : ""}
              </Text>
              <Text
                style={{
                  marginLeft: "5%",
                  marginBottom: "12px",
                  ...styles.arabic,
                }}
              >
                تاريخ
              </Text>
            </View>
          </View>
          <View style={styles.bottomSection}>
            <Text style={styles.address}>
              {payment?.company?.address} {"  "}
            </Text>
            <Text style={styles.phone}>
              {" "}
              T/F {payment?.company?.phone} - MOF#
              {payment?.company?.tax_number}
            </Text>
          </View>
        </Page>
      ))}
    </Document>
  )
}

export default PaymentReceiptPdf
